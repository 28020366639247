body {
  background-color: black;
  color: white;
  margin: 0;
  text-align: center;
}

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  font-family: 'Montserrat', sans-serif;
}

.container {
   display: flex;
   flex-direction: column;
   height: 100vh;
   justify-content: center;

   @media (max-height: 900px) {
     height: auto;
     display: block;
   }
 }

.wrapper {
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding: 80px 20px 60px;
  width: 940px;

  @media (max-width: 1800px) {
    padding: 30px 20px 30px;
  }
}

.logo {
  max-width: 100%;
  width: 460px;

  @media (min-width: 501px) and (max-width: 1800px) {
    width: 380px;
  }
}

.line {
  background-color: white;
  height: 3px;
  margin: 30px auto;
  width: 200px;
}

.line--top {
  margin-top: 60px;
}

.line--bottom {
  margin-bottom: 60px;
}

@media (width > 800px) and (height < 760px) {
  .line--top {
    margin-top: 30px;
  }
  .line--bottom {
    margin-bottom: 30px;
  }
}

h1 {
  font-family: orpheuspro, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 44px;
  letter-spacing: 0.5px;
  margin: 0;
  text-transform: uppercase;

  @media (max-width: 500px) {
    font-size: 36px;
  }

  @media (min-width: 501px) and (max-width: 1800px) {
    font-size: 40px;
  }
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 17px;
  letter-spacing: 0.5px;

  a {
    color: white;
    font-weight: 700;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 501px) and (max-width: 1800px) {
    font-size: 14px;
  }
}
